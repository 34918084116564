import { useFonts } from "expo-font";
import { wp, hp } from "../dimen";
/* A color theme for the app. */
const theme = {
  color: {
    brown: "#3C1518", //"#3C1518"
    superGray: "#434343",
    blue: "#2E5FF2", //[031927,2C2B3C,20063B,011936] main blue is 2E5FF2
    royalBlue: "#011936",
    blueCadet: "#111D4A",
    blueLI: "#083D77",
    royalGreen: "#0C1713",
    royalPurple: "#2F243A",
    strongBlue: "#1438A6",
    spaceCaderBlue: "#201E50",
    black: "#000",
    white: "#fff", //fbfbfb
    darkGray: "#3b3d56",
    yellow: "#e3c668",
    green: "#28913a", //28913a
    successGreen: "#4cbba1",
    strongGreen: "#62c654",
    lightGray: "#9f9f9f",
    aliceBlue: "#eef1f5",
    skyBlue: "#4b79Fb",
    red: "#F22B29",
    lightRed: "#ffebeb",
    aliceBrown: "#f7e1c8",
    gray: "#505558",
    blueText: "#9ba9be",
    orange: "#ed6e1f",
    disabled: "#EAEAEA",
    coolGreen: "#EBFF3C",
    lightMagnita: "#f2f2fe",
    lowGray: "#F2F2F2",
    magneta: "#A25D8A",
    greenNectar: "#00FF38",
    mainGreen: "#69e082",
    redDarkest: "#b71c1c",
    redDarker: "#c62828",
    redDark: "#d32f2f",
    redLight: "#ef5350",
    redLighter: "#e57373",
    redLightest: "#ef9a9a",
    // ---------------
  },
  SIZES: {
    bigTitle: hp(40),
    button: hp(19),
    title: hp(16),
    mainTitle: hp(30),
    underTitle: hp(18),
    phrase: hp(17),
  },
  //---------
  fontStyle: {},
};

export default theme;
//this color will be used as black for dark theme   "#202239",
