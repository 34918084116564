import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import {
  ImageBackground,
  Text,
  View,
  StyleSheet,
  Image,
  StatusBar,
  TouchableOpacity,
  TouchableOpacityBase,
  Dimensions,
  Pressable,
  ScrollView,
  useWindowDimensions,
  Linking,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { LinearGradient } from "expo-linear-gradient";
import { hp, wp } from "../../assets/dimen";
import theme from "../../assets/theme/colors";
import Work from "../UI/work";
import { Platform } from "react-native";
import { useFonts } from "expo-font";
import {
  MotiHover,
  MotiPressable,
  useMotiPressableTransition,
} from "moti/interactions";
import { Card } from "../UI/Technologies";

export default function Home(navigation) {
  const [fontsLoaded] = useFonts({
    "Charter-Italic": require("../../assets/fonts/Charter-Italic.otf"),
    "Garage-font": require("../../assets/fonts/garagefonts.woff2"),

    "Decimal-Book": require("..//../assets/fonts/Decimal-Book-Pro.woff"),
    "Decimal-Book-Bold": require("..//../assets/fonts/Decimal-Bold-Pro_Web.woff"),
    "Inter-Light": require("../../assets/fonts/Inter-Web/Inter-ExtraLight.woff"),
    "Inter-Regular": require("../../assets/fonts/Inter-Web/Inter-Light.woff"),
  });

  const windowWidth = useWindowDimensions().width;
  const touchAnimation = useMemo(
    () =>
      ({ hovered, pressed, pinched }) => {
        "worklet";
        return {
          translateY: hovered | pressed | pinched ? -5 : 1,
          borderColor:
            hovered | pressed | pinched ? theme.color.blue : theme.color.white,
        };
      },
    []
  );
  const transitionType = useMemo(
    () =>
      ({ hovered, pressed }) => {
        "worklet";

        return {
          type: "timing",
          duration: 200,
        };
      },
    []
  );

  const scrollRef = useRef();

  const onPressTouch = (X) => {
    scrollRef.current?.scrollTo({
      x: X,
      animated: true,
    });
  };

  const Menu = () => {
    return (
      <View
        style={{
          width: hp(300),
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: hp(20),
          position: "absolute",
          zIndex: 10,
        }}
      >
        <MotiPressable
          style={{
            borderBottomWidth: 5,
          }}
          animate={touchAnimation}
          transition={transitionType}
          onPress={() => onPressTouch(0)}
        >
          <Text style={styles.menuText}>HOME</Text>
        </MotiPressable>
        <MotiPressable
          style={{
            borderBottomWidth: 5,
          }}
          animate={touchAnimation}
          transition={transitionType}
          onPress={() => onPressTouch(windowWidth)}
        >
          <Text style={styles.menuText}>WORKS</Text>
        </MotiPressable>
        <MotiPressable
          style={{
            borderBottomWidth: 5,
          }}
          animate={touchAnimation}
          transition={transitionType}
          onPress={() => onPressTouch(windowWidth * 2)}
        >
          <Text style={styles.menuText}>SERVICES</Text>
        </MotiPressable>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <Menu></Menu>
      <ScrollView
        style={{ alignSelf: "stretch", width: "100%", paddingTop: 10 }}
        contentContainerStyle={{
          flex: 1,
          width: "100%",
        }}
        ref={scrollRef}
        horizontal={true}
        nestedScrollEnabled={true}
        scrollEnabled={false}
      >
        {/* home */}

        <View style={[styles.main]}>
          {/* <View style={{ flex: 1, backgroundColor: theme.color.black }}>
          </View> */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ alignSelf: "stretch", width: "100%" }}
            contentContainerStyle={{
              width: "100%",
            }}
          >
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: theme.SIZES.bigTitle,
                  //fontFamily: "Decimal-Book-Bold",
                  textAlign: "left",
                  // fontWeight: "bold",
                  fontFamily: "Garage-Font",
                }}
              >
                Hi, I'm Salah Eddine HAMMOUDA
              </Text>

              <View
                style={{
                  alignSelf: "stretch",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  marginVertical: hp(10),
                  alignContent: "center",
                  flexWrap: "wrap",
                  flexGrow: 1,
                }}
              >
                <View
                  style={{
                    alignSelf: "stretch",
                    alignItems: "center",
                    alignContent: "center",
                    backgroundColor: theme.color.white,
                    backgroundColor: theme.color.black,

                    flexGrow: 1,
                  }}
                >
                  <Image
                    style={{
                      height: "100%",
                      width: hp(5) > wp(5) ? hp(550) : hp(400),
                      resizeMode: "cover",
                      aspectRatio: 0.6,
                      backgroundColor: theme.color.black,
                    }}
                    source={require("../../assets/images/aFgdklf8ie8598dfaur90823490.jpg")}
                  ></Image>
                </View>
                <View
                  style={{
                    //  justifyContent: "center",
                    padding: hp(20),
                    //alignItems: "center",
                    backgroundColor: theme.color.white,

                    flex: 200,
                    minWidth: "40%",
                    borderBottomStartRadius: hp(5) > wp(5) ? 10 : 0,
                    borderBottomEndRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: theme.SIZES.bigTitle,
                      textAlign: "left",
                      color: theme.color.black,
                      fontFamily: "Garage-font",
                    }}
                  >
                    About
                  </Text>
                  <Text
                    style={{
                      fontSize: theme.SIZES.phrase,
                      textAlign: "left",
                      color: theme.color.gray,
                      fontFamily: "Helvetica",
                    }}
                  >
                    A senior software engineer with 10+ years of experience building diverse applications, from desktop software and casual 2D games to complex Augmented Reality solutions. Proven expertise in mobile development techniques, best practices, and timely delivery.
                  </Text>
                  <Text
                    style={{
                      fontSize: theme.SIZES.bigTitle,
                      textAlign: "left",
                      color: theme.color.black,
                      fontFamily: "Garage-font",
                    }}
                  >
                    Stack
                  </Text>

                  <Text
                    style={{
                      fontSize: theme.SIZES.phrase,
                      textAlign: "left",
                      color: theme.color.gray,
                      fontFamily: "Helvetica",
                    }}
                  >
                    These are my main craftmanship when it comes to backend and
                    frontend development.
                  </Text>
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: theme.color.white,
                      justifyContent: "space-around",
                      alignItems: "center",
                      paddingVertical: hp(20),
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Card
                        name={"React Native"}
                        desc={
                          "I've been using React Native for 7 years now and I have several apps under my belt, the framework never failed to amaze me!"
                        }
                        colors={[theme.color.strongBlue, theme.color.blue]}
                      ></Card>
                      <Card
                        name={".Net"}
                        desc={`I got exposed to the .Net framework back in 2011, it allows you to create high-quality apps more quickly.`}
                        colors={[theme.color.green, theme.color.royalBlue]}
                      ></Card>
                      <Card
                        name={"Unity 3D"}
                        desc={
                          "It's a fantastic tool for creating mobile and desktop apps, and I've been using it since version 4 when I produced two games and one augmented reality application."
                        }
                        colors={[theme.color.royalPurple, theme.color.magneta]}
                      ></Card>
                    </View>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          "https://www.linkedin.com/in/salah-eddine-h-7a116b194"
                        )
                      }
                      style={{
                        width: hp(200),
                        height: hp(45),
                        borderWidth: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Helvetica",
                          fontSize: theme.SIZES.button,
                        }}
                      >
                        HIRE ME
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              {/* links */}
              <View style={{ flexDirection: "row", marginVertical: hp(20) }}>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("https://twitter.com/salaheddin");
                  }}
                >
                  <Image
                    style={{
                      height: hp(25),
                      width: hp(25),
                      resizeMode: "contain",
                      marginHorizontal: hp(20),
                    }}
                    source={require("../../assets/images/2021 Twitter logo - blue.png")}
                  ></Image>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(
                      "https://www.linkedin.com/in/salah-eddine-h-7a116b194"
                    );
                  }}
                >
                  <Image
                    style={{
                      height: hp(25),
                      width: hp(25),
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/LI-In-Bug.png")}
                  ></Image>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
        </View>

        {/* works */}
        <View
          style={[
            styles.main,
            {
              justifyContent: "flex-start",
            },
          ]}
        >
          <Text
            style={{
              fontFamily: "Charter-Italic",
              fontSize: theme.SIZES.mainTitle,
            }}
          >
            Works
          </Text>
          <ScrollView
            style={{
              alignSelf: "stretch",
              width: "100%",
              flexGrow: 1,
              backgroundColor: theme.color.white,
            }}
            contentContainerStyle={{
              width: "100%",
              paddingHorizontal: hp(5) < wp(5) ? -hp(40) : 0,
              paddingBottom: 20,
            }}
          >
            <View>
              {/* CENTRAL BANK OF MOROCCO */}
              <Work
                title={'"60th Aniversary of Central Bank of Morocco"'}
                url={"https://loom.com/embed/8548a99c28bb41518a455f2bc081b674"}
                description={
                  "AR Mobile App that shows the design and security features of the modern Morrocan bills."
                }
                client={"The Central Bank of Morocco."}
                technologies={"Unity3D, .Net Web API, Let's Encrypt"}
              ></Work>
              {/* AGROSOURCE */}
              <Work
                title={'"Agrosource.org"'}
                isImage={true}
                url={"../../../assets/images/asDXcklj445ESFsdfASDF.png"}
                description={
                  "The landing page of the Moroccan exporting company Agrosource."
                }
                client={"Agrosource.org"}
                technologies={"React, .Net Web API, Let's Encrypt"}
              ></Work>
              {/* CIH */}
              <Work
                title={'"Check Scanner for CIH Bank"'}
                url={
                  "https://www.loom.com/embed/afb0e5f34efc4fa382c5d9069f530f79"
                }
                description={
                  "Mobile App that allows the user to scan and send cheques for pre-processing by the Bank."
                }
                client={"CIH BANK (one of the largest banks in Morocco)."}
                technologies={
                  "Envision Studio, React Native, .Net Api, Azure Cloud Hosting."
                }
              ></Work>
            </View>
          </ScrollView>
        </View>
        {/* Services */}
        <View style={[styles.main, { justifyContent: "flex-start" }]}>
          <View>
            <Text
              style={{
                fontFamily: "Charter-Italic",
                fontSize: theme.SIZES.mainTitle,
              }}
            >
              Services
            </Text>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ alignSelf: "stretch", width: "100%", flex: 1 }}
              contentContainerStyle={{
                flex: 1,
                height: "100vh",
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: "80vh",
                  paddingVertical: 20,
                  backgroundColor: theme.color.white,
                  alignItems: "center",
                  borderBottomStartRadius: 10,
                  borderBottomEndRadius: 10,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    padding: 10,
                    justifyContent: "space-evenly",
                  }}
                >
                  <Text
                    style={{
                      fontSize: theme.SIZES.phrase,
                      textAlign: "left",
                      color: theme.color.black,
                      fontFamily: "Charter-Italic",
                    }}
                  >
                    Throughout my experience, I've acquired various skills that
                    enable me to tackle any programming challenge with critical
                    thinking while adhering to customer requirements, so here's
                    my services:
                  </Text>
                  <View style={{ marginLeft: hp(10) }}>
                    <Text
                      style={{
                        fontSize: 30,
                        marginBottom: 8,
                        fontSize: theme.SIZES.bigTitle,
                        textAlign: "left",
                        color: theme.color.black,
                        fontFamily: "Garage-font",
                      }}
                    >
                      UI/UX
                    </Text>
                    <Text style={{ fontSize: hp(17), color: theme.color.gray }}>
                      Improve your application/website design and user journey
                      to unlock the potential of your business.
                    </Text>
                  </View>
                  <View style={{ marginLeft: hp(10) }}>
                    <Text
                      style={{
                        fontSize: 30,
                        marginBottom: 8,
                        fontSize: theme.SIZES.bigTitle,
                        textAlign: "left",
                        color: theme.color.black,
                        fontFamily: "Garage-font",
                      }}
                    >
                      Cross-Platform Mobile Applications
                    </Text>
                    <Text style={{ fontSize: hp(17), color: theme.color.gray }}>
                      Re-enter the market with a brand-new app and experience
                      using React Native with a single code base that supports
                      both Android and iOS.
                    </Text>
                  </View>
                  <View style={{ marginLeft: hp(10) }}>
                    <Text
                      style={{
                        fontSize: 30,
                        marginBottom: 8,
                        fontSize: theme.SIZES.bigTitle,
                        textAlign: "left",
                        color: theme.color.black,
                        fontFamily: "Garage-font",
                      }}
                    >
                      Back-end Microservices
                    </Text>
                    <Text style={{ fontSize: hp(17), color: theme.color.gray }}>
                      Scale your APIs and microservices using .Net or Node Js to
                      handle millions of calls per second!
                    </Text>
                  </View>
                  <View style={{ marginLeft: 10 }}>
                    <Text
                      style={{
                        fontSize: 30,
                        marginBottom: 8,
                        fontSize: theme.SIZES.bigTitle,
                        textAlign: "left",
                        color: theme.color.black,
                        fontFamily: "Garage-font",
                      }}
                    >
                      Unity 3D Games and AR
                    </Text>
                    <Text style={{ fontSize: hp(17), color: theme.color.gray }}>
                      Whether it's a simple game or an AR application, get the
                      best from the greatest game engine available.
                    </Text>
                  </View>
                  <TouchableOpacity
                    onPress={() =>
                      Linking.openURL(
                        "https://www.linkedin.com/in/salah-eddine-h-7a116b194"
                      )
                    }
                    style={{
                      width: hp(200),
                      height: hp(45),
                      borderWidth: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: theme.SIZES.button,
                      }}
                    >
                      HIRE ME
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </ScrollView>
      <LinearGradient
        colors={[theme.color.aliceBlue, theme.color.white, theme.color.white]}
        start={[1, 1]}
        end={[1, 0]}
        style={{
          width: "100vw",
          height: "100vh",
          padding: hp(20),
          position: "absolute",
          zIndex: -19,
        }}
      ></LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: Platform.select({ web: "100vh", default: undefined }),
    backgroundColor: theme.color.white,
    alignItems: "center",
    width: "100vw",
  },
  main: {
    width: "100vw",
    minHeight: Platform.select({ web: "95vh", default: hp(400) }),
    justifyContent: "center",
    borderBottomWidth: 5,
    paddingHorizontal: hp(5) < wp(5) ? hp(40) : hp(15),
    paddingTop: hp(60),
  },
  menuText: {
    fontSize: theme.SIZES.title,
    padding: hp(10),
    //fontFamily: "Decimal-Book",
  },
  explanation: {
    fontSize: theme.SIZES.underTitle,
    color: theme.color.gray,
    fontFamily: "default",
    lineHeight: 5,
  },
});
