import { View, Text, Image } from "react-native";
import theme from "../../assets/theme/colors";
import { wp, hp } from "../../assets/dimen";
import { LinearGradient } from "expo-linear-gradient";

export const Card = (props) => {
  return (
    <View
      style={{
        flexBasis: "100%",
        maxWidth: hp(400),
        borderRadius: hp(15),
        margin: hp(10),
      }}
    >
      <LinearGradient
        colors={props.colors}
        start={[1, 0]}
        end={[0, 2]}
        style={{
          flex: 1,
          padding: hp(20),
          borderRadius: hp(5),
        }}
      >
        <Text
          style={{
            fontSize: theme.SIZES.bigTitle,
            color: theme.color.yellow,
            fontFamily: "Inter-Light",
          }}
        >
          {props.name}
        </Text>
        <Text
          adjustsFontSizeToFit={true}
          style={{
            fontSize: theme.SIZES.underTitle,
            color: theme.color.white,
            fontFamily: "Inter-Regular",
          }}
        >
          {props.desc}
        </Text>
      </LinearGradient>
    </View>
  );
};
