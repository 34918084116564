import React, { useContext, useState, useEffect } from "react";
import {
  ImageBackground,
  Text,
  View,
  StyleSheet,
  Image,
  StatusBar,
  TouchableOpacity,
  TouchableOpacityBase,
  Dimensions,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Pressable } from "react-native-web";
import WebView from "react-native-webview";
import { hp, wp } from "../../assets/dimen";
import theme from "../../assets/theme/colors";
export default function Work(props) {
  return (
    <View
      style={{
        width: "100%",
        minheight: "100vh",
        justifyContent: "space-evenly",
        borderBottomWidth: hp(5),
        borderColor: theme.color.gray,
        paddingHorizontal: hp(5) < wp(5) ? hp(40) : 0,
        paddingVertical: hp(20),
        flexWrap: "wrap",
        alignContent: "center",
      }}
    >
      <Text
        style={{
          fontSize: theme.SIZES.underTitle,
          alignSelf: "center",
          color: theme.color.gray,
          fontFamily: "Charter-Italic",
        }}
      >
        {props.title}
      </Text>
      {/* video */}
      <View
        style={{
          width: hp(5) < wp(5) ? "90%" : "100%",
          height: hp(5) < wp(5) ? "70vh" : "70vw",
          backgroundColor: theme.color.gray,
          alignSelf: "center",
          marginVertical: hp(20),
        }}
      >
        {props.isImage ? (
          <Image
            source={require("../../assets/images/asDXcklj445ESFsdfASDF.png")}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <iframe
            width="100%"
            height="100%"
            src={props.url}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        )}
      </View>
      <View style={{ padding: 5 }}>
        <Text style={styles.brief}>
          <Text style={{ fontWeight: "bold", color: theme.color.black }}>
            Description:{" "}
          </Text>
          {props.description}
        </Text>
        <Text style={styles.brief}>
          <Text style={{ fontWeight: "bold", color: theme.color.black }}>
            Client:{" "}
          </Text>
          {props.client}
        </Text>
        <Text style={styles.brief}>
          <Text style={{ fontWeight: "bold", color: theme.color.black }}>
            Technologies:{" "}
          </Text>
          {props.technologies}
        </Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  menuText: { fontSize: theme.SIZES.title },
  brief: {
    fontSize: theme.SIZES.title,
    color: theme.color.gray,
  },
});
