import { useEffect, useState } from "react";
/*
 * Author: Ankur Gupta(ankurg22)
 */

import {
  widthPercentageToDP as wp2dp,
  heightPercentageToDP as hp2dp,
  listenOrientationChange as lor,
  removeOrientationListener as rol,
} from "react-native-responsive-screen-hooks";

/**
 * Width-Percentage
 * Converts width dimension to percentage
 * 360, 760 - design were made using this scale
 * @param dimension directly taken from design wireframes
 * @returns {string} percentage string e.g. '25%'
 */
export const wp = (dimension) => {
  return wp2dp((dimension / 1280) * 100 + "%");
};

/**
 * Height-Percentage
 * Converts width dimension to percentage
 * * 360, 760 - design were made using this scale
 * @param dimension directly taken from design wireframes
 * @returns {string} percentage string e.g. '25%'
 */
export function hp(dimension) {
  return hp2dp((dimension / 896) * 100 + "%");
}

export default function dimen() {
  const [orientation, setOrientation] = useState("portrait");

  useEffect(() => {
    // TODO: Pass setter function of useState to the listenOrientationChange as a parameter.
    // In case of class component you need to pass context of class that is 'this' keyword.
    // NOTE: This library will only work for functional component using useEffect
    // For class based component please refer the original library package :-
    // Original Author:- https://www.npmjs.com/package/react-native-responsive-screen
    lor(setOrientation);
    return () => {
      rol();
    };
  }, []);
}
